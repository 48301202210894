// * title
.main-title {
  font-size: 2.5rem;
  color: $color-blue-main;
  font-weight: normal;
}

// * alert
#__react-alert__ {
  z-index: 999;
}
.main-alert {
  width: calc(80vw - 32px);
  min-height: 50px;
  padding: 10px 17px;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 10px;
  span {
    margin-right: 8px;
  }
  &.success {
    border: 1px solid $color-blue-main;
    background: $color-blue-light;
    color: $color-blue-main;
  }
  &.error {
    border: 1px solid $color-red-main;
    background: $color-red-light;
    color: $color-red-main;
  }
}
div:first-child > .main-alert {
  margin-top: 30px;
}

// * button
.ui-button {
  height: 36px;
  display: inline-block;
  background: $color-blue-main;
  color: $color-white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.3s;
  &:hover {
    background: $color-blue-hover;
  }
}
.ui-button.button-main.disabled {
  background: $color-gray-text;
  cursor: default;
}
.ui-button.button-sub {
  background: transparent;
  border: 1px solid $color-blue-main;
  color: $color-blue-main;
  &:hover {
    border: 1px solid $color-blue-hover;
    color: $color-blue-hover;
  }
}
.ui-button.button-sub.disabled {
  border: 1px solid $color-gray-text;
  color: $color-gray-text;
  cursor: default;
}
.assign-area .ui-button.multi {
  margin: 0;
  margin-top: 24px;
}
.ui-button.multi:nth-child(2n) {
  margin-left: 8px;
}

@media screen and(max-width:960px) {
  .main-title {
    position: fixed;
    top: 12px;
    left: 60px;
    z-index: 801;
    font-size: 1.25rem;
  }
  .main-alert {
    width: 90vw;
    // left: 50%;
    // transform: translateX(-50%);
    // padding: 9px 17px;
  }
  div:first-child > .main-alert {
    margin-top: 0;
  }
}
