.login-wrapper,
.bind-wrapper {
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
  background: $color-blue-border-light;
  display: flex;
  justify-content: center;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.img-block {
  width: 65%;
}

.login-block {
  width: 500px;
  min-height: 100vh;
  padding: 15vh 5% 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 200px;
}

.input-block {
  width: 100%;
  margin-top: 20px;

  .ant-input-affix-wrapper {
    margin-top: 15px;
  }
}

.verify-block {
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;

  >div:first-child {
    flex: 1;
  }
}

.verify-img-block {
  width: 120px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.verify-img {
  width: 120px;
  height: 40px;
}

.verify-reload {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.forgot-pwd,
.to-login {
  // float: right;
  margin-top: 10px;
  cursor: pointer;
  color: $color-blue-main;
  transition: color 0.3s;

  &:hover {
    color: $color-blue-hover;
  }
}

.login-btn,
.bind-btn {
  width: 200px;
  min-height: 40px;
  display: inline-block;
  background: $color-blue-main;
  color: $color-white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.3s;

  &:hover {
    background: $color-blue-hover;
  }
}

.login-fail {
  color: $color-red-main;
}

.line-login {
  width: 200px;
  min-width: 200px;
  min-height: 40px;
  border: none;
  padding-left: 45px;
  // margin-top: 20px;
  background: $color-green-line;
  transition: background-color 0.3s;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  font-weight: 300;

  &:hover {
    background: $color-green-line-hover;

    .line-span {
      color: $color-white;
    }
  }
}

.line-span {
  border-left: 1px solid $color-green-border;
  display: block;
  line-height: 40px;
  transition: border-color 0.3s;
  text-align: center;
}

.line-logo {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.line-text {
  fill: $color-green-line;
  transition: fill 0.3s;
}

.line-login:hover .line-text {
  fill: $color-green-line-hover;
}

.line-login:hover .line-span {
  border-left: 1px solid $color-green-border-hover;
}

.login-wrapper,
.bind-wrapper {
  .btn-wrapper {
    width: 100%;
    margin-top: 30px;

    button {
      width: calc(50% - 5px);
      min-height: 40px;
      display: inline-block;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-weight: 300;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    }

    .reset-btn {
      background: $color-blue-main;
      color: $color-white;

      &:hover {
        background: $color-blue-hover;
      }
    }

    // .bind-btn {
    //   background: $color-green-line;
    //   color: $color-white;
    //   &:hover {
    //     background: $color-green-line-hover;
    //   }
    // }
  }
}

.login-wrapper .btn-wrapper .back-btn {
  background: transparent;
  border: 1px solid $color-blue-main;
  margin-left: 10px;

  &:hover {
    border-color: $color-blue-hover;
  }
}

.bind-wrapper .btn-wrapper .back-btn {
  background: transparent;
  border: 1px solid $color-green-line;
  margin-left: 10px;
  color: $color-green-line;

  &:hover {
    color: $color-green-line-hover;
    border-color: $color-green-line-hover;
  }
}

.bind-wrapper .btn-wrapper {
  margin-top: 0;
}

.login-wrapper,
.bind-wrapper {
  .title {
    color: $color-blue-main;
    margin-top: 10px;
    font-size: 1.425rem;
    text-align: center;
  }

  .subtitle {
    font-size: 1rem;
    color: $color-gray-dark;
    margin-top: 5px;
    text-align: center;
  }
}

.others-box {
  width: 100%;
  margin: 40px 0 20px;

  hr {
    border-top: 1px solid $color-gray-text;
  }

  p {
    color: $color-gray-text;
    background: $color-blue-border-light;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    min-width: 152px;
  }
}

.link-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

@media screen and(max-width:960px) {
  .img-block {
    display: none;
  }

  .login-block {
    width: 100%;
    max-width: 500px;
    min-width: auto;
    padding: 10vh 5%;
  }
}