.assign-wrapper {
  .select-wrapper {
    max-width: calc(100% - 346px);
    flex-wrap: wrap;
  }
}

.select-group {
  display: flex;
  align-items: center;
  margin-top: 5px;

  >span {
    margin: 0 10px;
  }
}

.control-box {
  display: flex;
  padding-right: 20px;
  z-index: 100;

  h5 {
    font-size: 1rem;
    line-height: 2.25rem;
  }

  .control-timePicker {
    max-width: 120px;
    width: auto;
  }

  >.select-wrapper {
    display: flex;
  }

  >.select-wrapper>div {
    margin-right: 20px;
  }

  >.button-wrapper {
    position: absolute;
    right: 20px;
    margin-top: 5px;
  }

  .unpub-dropdown {
    position: absolute;
    right: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    width: 146px;
    margin: 0;
    background: $color-white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;

    &.show {
      max-height: 72px;
    }

    p {
      text-align: center;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      font-size: 0.875rem;
      color: $color-gray-text;
      transition: color 0.3s;

      &:first-child {
        border-bottom: 1px solid $color-gray-light;
      }

      &:hover {
        color: $color-black;
      }
    }
  }
}

.show-item-btn {
  line-height: 34px;
  font-weight: 300;
  border-radius: 5px;
  border: 1px solid $color-blue-main;
  background: $color-white;
  padding: 0 20px;
  margin-right: 15px;
  color: $color-blue-main;
  cursor: pointer;

  &:focus-visible,
  &:active,
  &:focus {
    outline: none;
  }

  .anticon-eye,
  .anticon-eye-invisible {
    margin-right: 5px;
  }
}

.control-datePicker {
  max-width: 136px;
}

.control-timePicker {
  // max-width: 146px;
  width: 45%;
}

.control-people {
  width: 236px;

  .ant-select-selector {
    max-height: 90px;
    overflow-y: auto;
  }
}

.separator {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background: $color-blue-border-light;
}

.assign-area {
  width: 300px;
  height: 100%;
  padding: 60px 40px 60px 0;
  min-width: 300px;
  border-right: 1px solid $color-blue-border-light;
}

.assign-area.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-tag {
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.assign-title {
  color: $color-blue-main;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.assign-subtitle {
  color: $color-gray-text;
  font-size: 1rem;
  line-height: 2.25rem;
}

.assign-content {
  >h6 {
    margin-top: 10px;
    font-size: 0.75rem;
  }
}

.assign-area .ui-button {
  margin: 24px calc(50% - 73px);
}

.ant-select-multiple .ant-select-selection-item {
  background: $color-blue-main;
  color: $color-white;
  border-radius: 5px;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: $color-white;
}

.timeline-area {
  max-width: calc(100% - 360px);
  margin: 50px 20px 0 40px;
}

.area-del {
  text-decoration: line-through;
}

.area-del-tree span span {
  text-decoration: line-through;
}

.tree-group span {
  color: $color-black;
  cursor: default !important;
}

.ant-select-tree {
  border-radius: 5px !important;
}

.no-data-text {
  margin-top: 12px;
  color: $color-blue-main;
  font-size: 1rem;
}

.assign-close {
  display: none;
}

.add-btn,
.edit-btn {
  display: none;
  width: 60px;
  height: 60px;
  background: $color-white;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 888;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  .icon-plus svg {
    width: 40px;
    height: 40px;
    margin: 2.5px;
    color: $color-blue-main;
    display: block;
  }
}

.task-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.task-input {
  height: 36px;

  &.ant-input-affix-wrapper-disabled:hover {
    border-color: $color-gray-text !important;
  }
}

.add-task-btn {
  height: 36px;
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 300;
  transition: background-color 0.3s;
  background: $color-white;
  border: 1px dashed $color-blue-main;
  color: $color-blue-main;

  &:hover {
    border: 1px dashed $color-blue-hover;
    color: $color-blue-hover;
  }
}

.rct-vl.rct-day-0,
.rct-vl.rct-day-6 {
  background-color: white !important;
}

.sticky {
  &.tl {
    background-color: $color-white;
  }
}

@media screen and(max-width:960px) {
  .assign-wrapper {
    .select-wrapper {
      max-width: none;
    }
  }

  .select-group {
    width: calc(50% - 7.5px);
    max-width: 200px;
    margin-top: 0;
  }

  .only-desktop {
    display: none;
  }

  .control-box .control-datePicker {
    max-width: 200px;
    width: 100%;
    margin: 0;
  }

  .control-box .control-people {
    width: 100%;
    max-width: 200px;
  }

  .control-box {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0 auto;
    width: 90%;
  }

  .control-box h5 {
    display: none;
  }

  .control-box {
    >.select-wrapper {
      justify-content: center;
      margin-bottom: 10px;
      width: 100%;

      >div {
        margin: 0;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    >.button-wrapper {
      position: relative;
      right: 0;
      margin-top: 0;
    }
  }

  .control-datePicker {
    max-width: none;
  }

  .separator {
    display: none;
  }

  .assign-area,
  .assign-area.no-data {
    display: none;
    width: 90vw;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: $color-white;
    z-index: 960;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    height: auto;
    max-height: 80vh;
    border-right: none;
    overflow-y: auto;
  }

  .assign-wrapper.show {
    overflow: hidden;
    height: 100vh;

    .assign-area,
    .assign-area.no-data {
      display: block;
    }
  }

  .assign-wrapper .block {
    display: none;
  }

  .assign-wrapper.show .block {
    display: block;
  }

  .assign-area.show,
  .timeline-area {
    max-width: 90vw;
    margin: 60px auto 0;
  }

  .add-btn,
  .edit-btn {
    &.show {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .assign-close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: $color-gray-text;
  }

  .sticky {
    position: sticky;
    z-index: 1;

    &.tl {
      top: 54px;
    }
  }
}