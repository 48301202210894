.control-box.mywork {
  button.prev {
    margin-left: 30px;
  }

  button.next {
    margin-left: 5px;
  }

  button.prev-mo,
  button.next-mo {
    display: none;
  }
}

.mywork-wrapper {
  padding-bottom: 20px;

  .control-people {
    width: 120px;
  }
}

.mywork-title {
  color: $color-blue-main;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.ant-timeline-item-tail {
  border-left: 1px solid $color-gray-text;
}

.ant-timeline-item-content .separator {
  margin-bottom: 0;
}

.ant-timeline-item {
  padding-bottom: 10px;
}

.line-now {
  display: flex;
  align-items: center;
  transform: translateY(-23px);
}

.red-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  transform: translateX(-0.5px);
}

.red-line {
  width: 100%;
  border-top: 1px solid red;
}

.timeline-item {
  display: flex;
}

.wok-title-box {
  display: flex;
  min-width: 250px;
  height: 50px;
}

.wok-title {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: bold;
  color: $color-blue-main;
}

.icon-hint {
  margin-left: 10px;
  font-size: 0.75rem;
  color: $color-black;
  font-weight: normal;

  .unfnsh,
  .fnsh,
  .del {
    margin-left: 10px;
    background: $color-blue-main;
    color: $color-white;
    padding: 5px;
    border-radius: 5px;
  }

  .fnsh {
    opacity: 0.4;
  }

  .del {
    text-decoration: line-through;
  }
}

.mywork-wrapper .avatar-group {
  width: 100px;
}

.work-time.timeline {
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 0.875rem;
}

.area-list {
  display: flex;
  flex-wrap: wrap;
}

.area-list .area {
  padding: 8px 10px;
  background: $color-blue-main;
  color: $color-white;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 40px;

  &:not(.task-fnsh, .area-del):hover {
    background: $color-blue-hover;
  }

  &.task-fnsh {
    opacity: 0.4;
  }

  // &.area-del {
  //   background: $color-gray-text;
  // }
}

.mywork-wrapper {

  .ant-checkbox-input,
  .ant-checkbox,
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    width: 8.571428565px;
    height: 13.71428571px;
    transform: rotate(45deg) scale(1) translate(-60%, -60%);
  }
}

.mywork-wrapper .frozen-area {
  background: $color-white;
}

.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and(max-width:960px) {
  .mywork-wrapper {
    width: 90vw;
    margin: 0 auto;
  }

  .mywork-wrapper .control-box {
    align-items: flex-start;
    width: 100%;
  }

  .mywork-title {
    display: flex;
    flex-direction: column;
  }

  .icon-hint {
    margin-top: 10px;
    margin-left: -5px;
  }

  .date-btn-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .control-box.mywork {

    .prev,
    .next {
      display: none;
    }

    button.prev-mo,
    button.next-mo {
      display: inline;
      width: 30px;
      height: 30px;
      display: inline-block;
      background: #5e94fd;
      color: #ffffff;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      font-weight: 300;
      transition: background-color 0.3s;
    }

    button.next-mo {
      margin-left: 10px;
    }
  }

  // .prev,
  // .next {
  //   width: 30px !important;
  //   height: 30px;
  //   border-radius: 50%;
  //   color: transparent;
  //   &::after {
  //     content: "＜";
  //     font-weight: bold;
  //     color: $color-white;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-60%, -52%);
  //   }
  // }
  // .next::after {
  //   content: "＞";
  //   transform: translate(-45%, -52%);
  // }
  .ant-timeline .separator {
    display: block;
  }

  .timeline-item {
    flex-direction: column;
  }

  .wok-title-box {
    margin-bottom: 10px;
  }

  .loading-box {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .mywork-wrapper {
    .control-datePicker {
      max-width: none;
      width: 50%;
      flex-grow: 1;
    }

    .control-people {
      width: 50%;
    }

    .select-wrapper {
      width: calc(100% - 80px);
      justify-content: flex-start;
    }
  }
}