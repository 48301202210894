.ant-picker,
.ant-select-selector {
  border-radius: 5px !important;
  border: 1px solid $color-gray-text !important;
  min-height: 36px !important;
}

.ant-select-arrow,
.ant-select-clear,
.ant-picker-clear,
.ant-picker-suffix {
  svg {
    fill: $color-gray-icon !important;
  }
}

.ant-picker:hover,
.ant-picker-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused .ant-select-selector {
  border-color: $color-blue-main !important;
}

.ant-select-arrow,
.ant-select-clear {
  top: 17px !important;
}

.ant-picker-cell-inner.unpub::after {
  content: "";
  width: 100%;
  height: 4px;
  background: $color-red-main;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.ant-picker-disabled input {
  color: $color-black !important;
}

.spinner {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ant-tabs-tab {
  padding: 5px 20px;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 10px !important;
}

.ant-tabs-tab-btn {
  color: $color-gray-text;
  font-size: 1rem;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $color-blue-main;
  font-weight: 700;
}

.ant-tabs-ink-bar {
  background: $color-blue-main;
  border-radius: 5px;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 2px solid #f0f0f0;
  border-radius: 5px;
  max-width: 950px;
}

.ant-tooltip-content {
  position: static;
}

.ant-tabs {
  overflow: visible;
}

.ant-checkbox-disabled+span {
  color: #ababab;
}

.ant-checkbox-wrapper {
  color: $color-black;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-blue-main;
  border: 1px solid #5e94fd;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $color-blue-main;
  width: 12px;
  height: 12px;
}

.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  border: 1px solid $color-gray-text;
  border-radius: 5px;

  .ant-input-prefix {
    color: $color-gray-text;
  }
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $color-blue-main;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: $color-red-main;

  .ant-input-prefix {
    color: $color-red-main;
  }
}

.ant-picker-time-panel-column::after {
  display: none;
}

@media screen and(max-width:960px) {

  .date-picker-wrapper,
  .ant-select-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 962;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }

    &>div {
      position: fixed!important;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: .2);
    }
  }

  .area-range-picker {
    position: static !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    &.ant-picker-dropdown-hidden {
      display: none;
    }
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-dropdown {
    position: absolute;
    // top: 0 !important;
    // left: 50% !important;
    // transform: translateX(-50%) !important;
    z-index: 961;
    // &::before {
    //   content: "";
    //   position: fixed;
    //   top: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: 100vw;
    //   height: 100vh;
    //   background: rgba($color: $color-black, $alpha: 0.4);
    //   z-index: 0;
    // }
  }

  .ant-picker-panel-container {
    // transform: translateY(100px);
    border-radius: 5px;
  }

  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel {
    width: 90vw !important;
    max-width: 350px;
  }
  
  .ant-picker-time-panel {
    width: calc(90vw - 80px);
  }

  .ant-picker-content,
  .ant-picker-cell .ant-picker-cell-inner {
    width: 100% !important;
  }

  .ant-picker-cell-inner.unpub::after {
    width: 70%;
  }

  .ant-select-dropdown {
    // width: 90vw !important;
    position: static;
    // top: 30vh !important;
    // left: 5vw !important;
    z-index: 961;
    overflow: visible;
    padding: 0;

    // &::before {
    //   content: "";
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100vw;
    //   height: 100vh;
    //   background: rgba($color: $color-black, $alpha: 0.4);
    //   touch-action: none;
    //   user-select: none;
    // }
    .ant-select-item,
    .ant-select-item-empty {
      background: #ffffff;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #e6f7ff;
    }

    .rc-virtual-list-holder,
    .ant-select-item-empty {
      border-radius: 5px;
    }
  }

  .ant-select-multiple {
    input {
      display: none;
    }
  }
}