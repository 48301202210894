@media screen and (max-width: 960px) {
    .tmpwork-wrapper {
        width: 90vw;
        margin: 0 auto;

        .control-box {
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }

        .function-box .set-show {
            right: 5vw;
        }
    }

    .control-box.overview {
        .control-tmp {
            max-width: 200px;
            width: 100%;
        }

        .control-datePicker {
            max-width: 200px;
            width: 100%;
            margin: 0;
        }
    }
}