.layout-wrapper {
  // width: 100vw;
  height: 100vh;
  // overflow: hidden;
}

.children-wrapper {
  // height: 100vh;
  // overflow-y: auto;
}

.home-wrapper,
.assign-wrapper,
.overview-wrapper,
.tmpwork-wrapper,
.mywork-wrapper,
.notice-wrapper {
  padding: 30px 20px 0 100px;
}

.nav-wrapper {
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: $color-blue-border-light;
  width: 80px;
  height: 100vh;
  transition: width 0.3s, margin 0.3s, transform 0.3s;
  z-index: 99999;
  padding-top: 25px;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:hover {
    overflow-y: auto;
  }
}

.logo-box {
  display: flex;
  justify-content: center;
  height: 50px;
  margin-bottom: 20px;
  transition: inherit;
  cursor: pointer;

  svg {
    width: 70px;
    transition: inherit;
  }
}

.avatar-box {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 15px;
  width: 100%;
  transition: inherit;
}

.nav-avatar {
  margin-right: 14px;
}

.nav-wrapper .avatar-box .text-area {
  width: 0;
  transition: inherit;

  h4,
  h6 {
    white-space: nowrap;
  }

  h6 {
    color: $color-gray-text;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  h4 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.nav-item {
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 34px;
  transition: fill 0.2s, color 0.2s;
  cursor: pointer;

  svg:first-child {
    width: 30px;
    fill: $color-gray-text;
    transition: inherit;
  }
}

.nav-item .text-area {
  width: 100px;
  transition: inherit;
  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  margin-left: 15px;
  transition: inherit;

  h3 {
    white-space: nowrap;
    color: $color-gray-text;
    font-size: 1rem;
    transition: inherit;
  }

  .arrow {
    color: $color-gray-text;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s;
  }
}

.nav-item.active {
  border-left: 10px solid $color-blue-main;
  padding-left: 24px;
  background: $color-white;

  >svg:first-child {
    fill: $color-blue-main;
  }

  .text-area h3 {
    color: $color-black;
  }
}

.nav-item:hover {
  >svg:first-child {
    fill: $color-blue-main;
  }

  .text-area h3 {
    color: $color-black;
  }
}

.nav-item.open .arrow {
  transform: translateY(-50%) rotate(90deg);
}

.nav-wrapper:hover {
  width: 250px;
}

.nav-wrapper:hover {
  .logo-box svg {
    width: 140px;
  }

  .avatar-box {
    margin-left: 40px;
    margin-bottom: 15px;
  }

  .avatar-box .text-area {
    margin-left: 4px;
  }

  .nav-item .text-area {
    width: 170px;
  }

  .logout-btn {
    display: block;
  }
}

.nav-dropdown {
  overflow: hidden;
  transition: height 0.2s;
}

.nav-dropdown:not(.open) {
  height: 0 !important;
}

.nav-dropdown-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 79px;
  cursor: pointer;

  h4 {
    transition: color 0.2s;
    font-size: 1rem;
    color: $color-gray-text;
  }
}

.nav-dropdown-item.active {
  h4 {
    color: $color-blue-main;
  }
}

.nav-dropdown-item:not(.active):hover {
  h4 {
    color: $color-black;
  }
}

.header,
.block {
  display: none;
}

.logout-btn {
  font-size: 1rem;
  font-weight: 500;
  color: $color-gray-text;
  display: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 40px;

  &:hover {
    color: $color-blue-main;
    border-color: $color-blue-main;
  }
}

.wrapper {
  padding-bottom: 20px;

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;

    svg {
      width: 70px;
      height: 50px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 1rem;
      color: $color-blue-main;
    }
  }
}

.mobile {
  display: none;
}

@media screen and(max-width:960px) {
  .home-wrapper,
  .assign-wrapper,
  .overview-wrapper,
  .tmpwork-wrapper,
  .mywork-wrapper,
  .notice-wrapper {
    padding: 64px 0 0 0;
  }

  .logout-btn {
    display: block;
  }

  .nav-wrapper {
    transform: translateX(-100%);
    width: 250px;
    overflow-y: auto;
  }

  .nav-wrapper.open {
    transform: translateX(0);
  }

  .nav-wrapper.open {
    width: 250px;
  }

  .nav-wrapper.open {
    .logo-box svg {
      width: 140px;
    }

    .avatar-box {
      margin-left: 40px;
    }

    .avatar-box .text-area {
      margin-left: 4px;
    }

    .nav-item .text-area {
      width: 170px;
    }
  }

  .nav-dropdown {
    height: 100% !important;
  }

  .nav-dropdown-item:not(.active).open {
    h4 {
      color: $color-black;
    }
  }

  .header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    background: $color-blue-border-light;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 800;
  }

  .block {
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.16);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 950;
  }

  .icon-menu {
    color: $color-blue-main;
    font-size: 25px;
    margin-left: 17px;
  }

  .mobile {
    display: block;
  }
}