.notice-wrapper {
    .control-box {
        .ant-picker-range {
            max-width: 220px;
            margin-right: 25px;
            margin-left: 10px;
        }

        .ant-input {
            max-width: 238px;
            margin-left: 10px;
            margin-right: 50px;
        }
    }
}