.popup-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 100000;
}
.popup-box {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-height: 80vh;
  max-width: 740px;
  z-index: 100000;
  background: $color-white;
  border-radius: 5px;
  padding: 20px;
  overflow-y: auto;
}
.popup-title {
  text-align: center;
  font-size: 1.25rem;
  color: $color-blue-main;
}
.popup-sepa {
  width: 100%;
  margin: 10px 0;
  border-top: 1px solid $color-blue-light-2;
}
.popup-content {
  color: $color-blue-main;
  font-size: 1rem;
  min-height: 70px;

  pre {
    white-space: pre-wrap;
  }

  img {
    margin: 0 auto;
  }
}
.popup-btns {
  width: 146px;
  margin: 20px auto 0;
  &.double {
    width: 230px;
  }
}
.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: $color-gray-text;
}

.popup-iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 66.66%;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.popup-content-wrapper {
  color: $color-black;
}

.popup-content-title {
  font-size: 1.125rem;
}
