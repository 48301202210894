.home-wrapper {
    max-width: 1500px;
    padding-bottom: 20px;

    h2 {
        font-size: 40px;
        color: $color-blue-main;
        margin-bottom: 15px;
    }

    ul {
        padding-left: 20px;
    }

    ul,
    li {
        list-style: disc;
    }

    .separator {
        display: block;
    }

    .content {
        font-size: 16px;
    }

    .mb50 {
        margin-bottom: 50px;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .ml20 {
        margin-left: 20px;
    }
}

.function-card-wrapper {
    display: flex;
}

.function-card {
    display: flex;
    align-items: center;
    padding: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    flex: 1 1 33.333%;
    border-radius: 5px;

    figure {
        margin-right: 30px;
        margin-bottom: 0;
    }

    svg {
        width: 50px;
        height: 50px;
        display: block;
        fill: $color-blue-main;
    }

    .content {
        align-self: flex-start;
        flex-grow: 1;
    }

    &+.function-card {
        margin-left: 20px;
    }

    .card-title {
        margin-bottom: 10px;
    }
}

.card-title {
    display: flex;
    font-size: 20px;
    color: $color-blue-main;
    margin-bottom: 20px;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
        display: block;
        fill: $color-blue-main;
        margin-right: 5px;
    }
}

@media screen and (min-width: 961px) {}

@media screen and (max-width: 960px) {
    .home-wrapper {
        width: 90%;
        margin: 0 auto;
    }

    .function-card-wrapper {
        flex-direction: column;
    }

    .function-card {
        &+.function-card {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .g-calendar-wrapper {
        iframe {
            width: 100%;
        }
    }
}