.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: transparent;
}
.react-calendar-timeline .rct-dateHeader {
  background: white;
  justify-content: flex-start;
  border-left: none;
  border-bottom: none;
}
.react-calendar-timeline .rct-calendar-header {
  border: none;
}
.rct-items {
  position: static;
}
.react-calendar-timeline .rct-item {
  border-radius: 5px;
  // border: none !important;
  border: 1px solid $color-white !important;
  &::before {
    content: "";
  }
}
.rct-item-bg {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
}
.react-calendar-timeline .rct-item .rct-item-content {
  line-height: 25px;
  white-space: nowrap;
  width: 100%;
  border-radius: 5px;
  padding: 10px 14px;
  transition: 0.3s;
  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .item-content-tmp {
    color: $color-gray-dark;
    font-size: 0.75rem;
    line-height: 1.0625rem;
  }
  .item-content-wok,
  .item-content-sto {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &:hover {
    opacity: 1 !important;
  }
}
.react-calendar-timeline .rct-item.selected {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #C2E5FA;
    border: 1px solid white;
    z-index: 1;
  }
  &::before {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
  &::after {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }
}

.react-calendar-timeline .rct-scroll {
  width: 100% !important;
}
.rct-outer {
  overflow-y: scroll !important;
  max-height: calc(100vh - 280px);
}
.rct-scroll::-webkit-scrollbar {
  display: none;
}
.react-calendar-timeline .rct-header-root {
  border-bottom: 1px solid $color-blue-light;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid $color-blue-light;
}
.react-calendar-timeline .rct-sidebar {
  border-right: 1px solid $color-blue-light;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid $color-blue-light;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid $color-blue-light!important;
}
.rct-vertical-lines {
  user-select: none;
}

@media screen and(max-width:960px) {
  .rct-outer {
    max-height: none;
    overflow-y: hidden!important;
    z-index: 0;
  }

  .rct-vl, .rct-calendar-header {
    transition: .2s ease-in-out;
  }
}
