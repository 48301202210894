$color-white: #ffffff;
$color-blue-light: #dff0fa;
$color-blue-border-light: #eaf5fc;
$color-blue-light-2: #7ea9fd;
$color-blue-hover: #70a0fd;
$color-blue-main: #5e94fd;
$color-red-light: #ffccc7;
$color-red-main: #ff4d4f;
$color-orange: #ff3b00;
$color-gray-light: #e2e2e2;
$color-gray-icon: #ababab;
$color-gray-text: #9f9f9f;
$color-gray-dark: #505050;
$color-black: #000000;
$color-green-line: #00c300;
$color-green-line-hover: #00e000;
$color-green-border: #00b300;
$color-green-border-hover: #00c900;

html {
  scroll-behavior: smooth;
}

html,
body {
  font-size: 16px;
  color: $color-black;
}
* {
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans TC", sans-serif;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $color-black;
}
p {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}