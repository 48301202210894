.control-box.overview {
  .control-datePicker {
    margin: 0 8px;
  }

  .control-tmp {
    width: 170px;
    margin-left: 8px;
  }
}

.hint-box {
  margin-left: 110px;

  svg {
    width: 150px;
    height: 150px;
  }

  &.tmp svg {
    margin-left: 18px;
  }

  &.wok svg {
    margin-left: 13px;
  }

  p {
    color: $color-blue-main;
    margin-top: 10px;
  }
}

.wok.mobile {
  display: none;
}

.overview-title {
  color: $color-blue-main;
  font-size: 1.25rem;
  font-weight: bold;

  .wok {
    cursor: pointer;
    user-select: none;
  }

  .arrow {
    display: none;
  }
}

.count-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
}

.count-item {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  border: 3px solid #ffffff;
  box-sizing: content-box;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 6px 7px 6px rgba(0, 0, 0, 0.4);
  }
}

.count-item.selected {
  box-shadow: 6px 7px 6px rgba(0, 0, 0, 0.4);
  cursor: default;
}

.count-work {
  color: $color-white;
  font-size: 1rem;
  font-weight: normal;
}

.count-num {
  font-size: 3.8rem;
  line-height: 3.8rem;
  margin-top: 3px;
  color: $color-white;
}

.count-item .alert {
  width: 24px;
  height: 24px;
  background: $color-white;
  border-radius: 50%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: -4px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    color: $color-red-main;
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.wok-item {
  margin-bottom: 35px;
}

.woklist-title-box {
  display: flex;
  align-items: center;
  max-width: 950px;

  ~.woklist-title-box {
    margin-top: 35px;
  }
}

.tips-icon {
  color: $color-blue-main;
  margin-left: 6px;
  font-size: 1.125rem;
  margin-top: 1px;
  cursor: pointer;
}

.avatar-group {
  margin-left: 20px;
}

.work-time {
  font-size: 0.875rem;

  &.countdown {
    color: $color-orange;
  }
}

.store-card-list,
.task-card-list {
  display: none;
}

.store-table,
.task-table {
  max-width: 950px;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin: 10px 0;
  border-radius: 5px;
  overflow: hidden;

  th {
    text-align: left;
  }
}

.store-table thead,
.task-table thead {
  background: $color-blue-light;
  height: 46px;

  th {
    font-weight: 500;
    font-size: 1rem;
  }

  th:first-child {
    padding-left: 30px;
  }
}

.task-table thead {
  th:nth-child(1) {
    width: 63%;
  }

  th:nth-child(2) {
    width: 16%;
  }

  th:nth-child(3) {
    width: 21%;
  }
}

.store-table tbody,
.task-table tbody {
  tr {
    height: 80px;
    border: 1px solid $color-blue-light;
  }

  td {
    font-size: 0.875rem;
  }

  td:first-child {
    padding-left: 30px;
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.store-table tbody tr {
  cursor: pointer;
}

.place-box {
  width: 23%;
}

.progress-box {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;

  .num {
    display: block;
    min-width: 10%;
  }
}

.store-table .progress-bar {
  width: 250px;
  margin-left: 35px;
}

.upd-time {
  width: 31%;
}

.function-box {
  display: flex;
  position: absolute;
  right: 0;

  .prev,
  .set-show {
    cursor: pointer;
    color: $color-gray-text;
    user-select: none;
    font-size: 1.25rem;

    span {
      margin-left: 6px;
    }
  }

  .set-show {
    margin-left: 15px;
  }
}

.prev .arrow {
  display: none;
}

.task-table thead th:first-child {
  padding-left: 28px;
}

.task-table tbody {
  td:first-child span {
    font-size: 1.25rem;
    font-weight: bold;
  }

  td:first-child {
    padding-left: 0;
  }
}

.task-content {
  .ant-checkbox-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding-left: 28px;
    align-items: center;
  }

  .ant-checkbox {
    top: 0;
  }
}

.task-content,
.task-title {
  width: 63%;
}

@keyframes checkani {
  0% {
    transform: rotate(45deg) scale(1) translate(-60%, -60%);
    border-width: 2px;
  }

  80% {
    transform: rotate(45deg) scale(1.8) translate(-60%, -60%);
    border-width: 6px;
  }

  100% {
    transform: rotate(45deg) scale(1) translate(-60%, -60%);
    border-width: 2px;
  }
}

.overview-wrapper,
.mywork-wrapper,
.tmpwork-wrapper {

  .ant-checkbox-input,
  .ant-checkbox,
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {

      &::after {
        width: 8.571428565px;
        height: 13.71428571px;
        transform: rotate(45deg) scale(1) translate(-60%, -60%);
        border: 2px solid #fff !important;
        border-top: 0 !important;
        border-left: 0 !important;
      }
    }
  }
}

.task-title .ant-checkbox-wrapper {
  margin-right: 10px;
}

.task-people {
  width: 16%;
}

.fnsh-time {
  width: 21%;
}

.finish {
  display: none;

  td {
    opacity: 0.4;
  }

  .task-content span {
    text-decoration: line-through;
  }
}

.finish.disabled {
  td:first-child {
    opacity: 1;
  }
}

.show-fnsh .finish {
  display: table-row;
}

.select-all-box {
  display: none;
}

.overview-wrapper,
.tmpwork-wrapper {
  .ant-tabs-nav {
    position: sticky;
    top: -30px;
    background: $color-white;
    z-index: 99;
  }
}

.overview-wrapper,
.tmpwork-wrapper {
  .ant-tabs-content-holder {
    min-height: calc(100vh - 50px);
  }
}

@media screen and(max-width:960px) {
  .overview-wrapper {
    width: 90vw;
    margin: 0 auto;
  }

  .overview-wrapper .control-box {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .hint-box {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.tmp svg {
      margin-left: 35px;
    }

    &.wok svg {
      margin-left: 0;
    }
  }

  .overview-title {
    display: flex;
    align-items: center;

    .arrow {
      display: block;
      font-size: 0.875rem;
      margin-left: 5px;
      transform: rotate(-90deg);
      transition: transform 0.3s;
    }

    &.open .arrow {
      transform: rotate(0);
    }
  }

  .count-box {
    padding-top: 10px;
  }

  .count-list {
    display: none;

    &.open {
      display: flex;
    }
  }

  .count-item {
    margin-bottom: 10px;
  }

  .store-table,
  .task-table {
    display: none;
  }

  .store-card-list,
  .task-card-list {
    display: block;
  }

  .store-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px 15px 5px 15px;

    h2 {
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.75rem;
      display: flex;
      justify-content: space-between;
    }

    .ant-progress {
      display: block;
    }

    .progress-bar {
      margin-top: -5px;
    }
  }

  .task-card {
    border-bottom: 1px solid $color-blue-border-light;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    min-height: 60px;

    &.finish {
      display: none;

      label,
      span,
      p {
        opacity: 0.4;
      }

      label span {
        opacity: 1;
      }

      span:nth-child(2) {
        text-decoration: line-through;
      }
    }

    &.finish.disabled {
      display: none;

      label,
      span,
      p {
        opacity: 1;
      }

      p {
        color: $color-gray-icon;
      }
    }

    span:nth-child(2) {
      font-size: 1rem;
      font-weight: 500;
    }

    .ant-checkbox-wrapper {
      align-items: center;
      flex-grow: 1;
    }

    .ant-checkbox {
      top: 0;
    }

    .ant-avatar {
      margin-top: 2px;
      flex-shrink: 0;
    }
  }

  .show-fnsh .task-card.finish {
    display: flex;
  }

  .fnsh-time {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 32px;
    font-size: 0.625rem;
  }

  .function-box .set-show {
    color: $color-blue-main;
    position: fixed;
    top: 7px;
    right: 48px;
    z-index: 800;
    font-size: 24px;
  }

  .task-hide {

    .main-title,
    .show-text,
    .function-box .prev-content,
    .control-box.overview,
    .count-box {
      display: none;
    }
  }

  .function-box .prev span,
  .function-box .set-show span {
    margin: 0;
  }

  .prev .arrow {
    display: block;
    color: $color-blue-main;
    font-size: 30px;
    background: $color-blue-border-light;
    z-index: 800;
    position: fixed;
    top: 12px;
    left: 15px;
  }

  .overview-title.task-title {
    position: fixed;
    top: 10px;
    left: 50px;
    z-index: 800;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 0;

    .area {
      line-height: 1.25rem;
    }

    .slash {
      display: none;
    }

    .wok {
      font-size: 0.75rem;

      &.tmp {
        font-size: inherit;
      }
    }

    .tips-icon {
      position: fixed;
      top: 14px;
      right: 80px;
      font-size: 1.5rem;
    }
  }

  .wok,
  .tips-icon.mobile {
    display: none;
  }

  .wok.mobile {
    display: block;
  }

  // .select-all-box {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: fixed;
  //   left: calc(5vw + 20px - 13px);
  //   bottom: 24px;
  //   width: 50px;
  //   height: 50px;
  //   border-radius: 50%;
  //   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  //   cursor: pointer;
  //   .ant-checkbox-inner {
  //     border: 1px solid $color-blue-main;
  //   }
  // }
  .select-all-box {
    display: block;
    position: fixed;
    top: 12px;
    right: 15px;
    z-index: 800;
    width: 24px;
    height: 24px;

    &.tmp {
      position: relative;
      top: auto;
      right: auto;
      height: auto;
      width: auto;
      padding: 12px 20px;
      border-bottom: 1px solid #eaf5fc;
      z-index: 0;

      span {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .ant-checkbox-inner {
      border: 1px solid $color-blue-main;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid $color-blue-border-light;
      border-top: 0;
      border-left: 0;
    }
  }

  .overview-wrapper,
  .tmpwork-wrapper {
    .ant-tabs-nav {
      top: 54px;
    }
  }

  .overview-wrapper,
  .tmpwork-wrapper {
    .ant-tabs-content-holder {
      min-height: calc(100vh - 100px);
    }
  }
}